:root {
    --color-bg-dark: #f5f5f5; /* 어두운 배경 색상 */
    --color-bg: #fdfffd; /* 배경 색상 */
    --color-grey: #c7c1c1; /* 회색 색상 */
    --color-text: #22243b; /* 텍스트 색상 */
    --color-accent: #0788ac; /* 강조 색상 */
    --color-hover: #f57206; /* hover 색상 */
    --color-white: #ffffff;
    --color-scrollbar: #aaa7a7;
}

html.dark {
    --color-bg-dark: #262626;
    --color-bg: #4b4b4d;
    --color-grey: #4e4e4e; /* 회색 색상 */
    --color-text: #f1f2f8; /* 텍스트 색상 */
    --color-accent: #02c2f7; /* 강조 색상 */
    --color-hover: #f57206; /* hover 색상 */
    --color-white: #ffffff;
    --color-scrollbar: #aaa7a7;    
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw; /* 너비를 뷰포트의 너비로 설정 */
    height: 100vh; /* 높이를 뷰포트의 높이로 설정 */
    display: flex; /* Flexbox 레이아웃을 사용하여 요소를 배치 */
    justify-content: center; /* 가로 방향으로 요소를 가운데 정렬 */
    align-items: center; /* 세로 방향으로 요소를 가운데 정렬 */
    font-size: 1.2rem;
    background: rgb(2, 69, 93);
    background: linear-gradient(17deg, rgba(2, 69, 93, 1) 0%, rgba(2, 168, 205, 1) 54%, rgba(0, 200, 255, 1) 100%);
    accent-color: var(--color-accent);
}

#root {
    width: 100%;
    height: 500px;
    max-width: 350px;
    background-color: var(--color-bg-dark);
    overflow: hidden;
    border-radius: 1rem; /* 폰트크기(root em)를 기준으로 radious 값을 지정함 */
    display: flex;
    flex-direction: column;
    box-shadow: 5px 3px 37px -11px rgba(10, 10, 10, 0.75);
    -webkit-box-shadow: 5px 3px 37px -11px rgba(10, 10, 10, 0.75);
    -moz-box-shadow: 5px 3px 37px -11px rgba(10, 10, 10, 0.75);
}

/* 모든 요소에 대해 box-sizing 속성을 설정하는 부분 
   border-box 값은 요소의 너비와 높이를 "border-box"로 계산하도록 지정. 
   이 경우, 요소의 너비와 높이에는 요소의 내부 여백과 테두리의 크기가 포함e됨. 
   따라서, 내부 여백과 테두리의 크기가 요소의 전체 크기에 영향을 주지 않고, 
   지정된 너비와 높이에 따라 요소의 콘텐츠 영역이 조정됨.
*/

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
    list-style: none;
    padding-left: 0;
}

button {
    outline: none;
    border: none;
}

::-webkit-scrollbar {
    width: 0.5rem;
}

::-webkit-scrollbar-track {
    background-color: var(--color-bg-dark);
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-scrollbar);
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-accent);
}