.form {
    width: 100%; /* 폼의 너비를 100%로 설정 */
    display: flex; /* Flexbox 레이아웃을 사용 */
    padding: 1.2rem 1rem; /* 내부 여백 설정 */
    background-color: var(--color-bg-dark); /* 배경색을 --color-bg-dark 변수로 지정 */
    border-top: 1px solid var(--color-grey); /* 위쪽에 회색 경계선 추가 */
}

.input {
    flex: 1 0 auto; /* Flex 아이템이 가변적인 너비를 가지도록 설정 */
    font-size: 1.2rem; /* 폰트 크기를 1.2rem으로 설정 */
    padding: 0.5rem 0.5rem; /* 내부 여백 설정 */
    border: none; /* 테두리 없음 */
    outline: none; /* 포커스 표시 없음 */
    border-top-left-radius: 8px; /* 좌측 상단 테두리 반경값 설정 */
    border-bottom-left-radius: 8px; /* 좌측 하단 테두리 반경값 설정 */
}

.button {
    cursor: pointer; /* 커서를 포인터로 변경하여 클릭 가능한 상태 표시 */
    background-color: var(--color-accent); /* 배경색을 --color-accent 변수로 지정 */
    color: var(--color-white); /* 글자색을 --color-white 변수로 지정 */
    font-weight: bold; /* 글자 굵기를 bold로 설정 */
    font-size: 1rem; /* 폰트 크기를 1rem으로 설정 */
    padding: 0.5rem; /* 내부 여백 설정 */
    border-top-right-radius: 8px; /* 우측 상단 테두리 반경값 설정 */
    border-bottom-right-radius: 8px; /* 우측 하단 테두리 반경값 설정 */
}

.button:hover {
    filter: brightness(125%); /* 호버 상태일 때 밝기를 125%로 조정하여 더 밝은 표시 */
}